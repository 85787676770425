@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7vjcoe');
  src:  url('fonts/icomoon.eot?7vjcoe#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?7vjcoe') format('truetype'),
    url('fonts/icomoon.woff?7vjcoe') format('woff'),
    url('fonts/icomoon.svg?7vjcoe#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-admin-rocket:before {
  content: "\e96c";
}
.icon-admin-coin:before {
  content: "\e964";
}
.icon-admin-eye:before {
  content: "\e965";
}
.icon-admin-home:before {
  content: "\e966";
}
.icon-admin-play:before {
  content: "\e967";
}
.icon-admin-setting:before {
  content: "\e968";
}
.icon-admin-star:before {
  content: "\e969";
}
.icon-admin-users:before {
  content: "\e96a";
}
.icon-admin-video:before {
  content: "\e96b";
}
.icon-chevron-down-bold:before {
  content: "\e962";
}
.icon-chevron-up-bold:before {
  content: "\e963";
}
.icon-admin-settings:before {
  content: "\e961";
}
.icon-next:before {
  content: "\e95f";
}
.icon-prev:before {
  content: "\e960";
}
.icon-waiting:before {
  content: "\e95e";
}
.icon-minus:before {
  content: "\e95d";
}
.icon-trash2:before {
  content: "\e95c";
}
.icon-home:before {
  content: "\e95b";
}
.icon-plus:before {
  content: "\e95a";
}
.icon-back-arrow:before {
  content: "\e959";
}
.icon-pause:before {
  content: "\e912";
}
.icon-play:before {
  content: "\e92e";
}
.icon-rocket:before {
  content: "\e931";
}
.icon-stop:before {
  content: "\e934";
}
.icon-star:before {
  content: "\e92d";
}
.icon-home-round:before {
  content: "\e947";
}
.icon-instagram-round:before {
  content: "\e948";
}
.icon-phone-round:before {
  content: "\e949";
}
.icon-telegram-round:before {
  content: "\e94a";
}
.icon-clock-active:before {
  content: "\e94b";
}
.icon-clock:before {
  content: "\e94c";
}
.icon-brake-warning:before {
  content: "\e946";
}
.icon-chat:before {
  content: "\e90b";
}
.icon-mail-bulk:before {
  content: "\e90d";
}
.icon-mail:before {
  content: "\e911";
}
.icon-camera:before {
  content: "\e913";
}
.icon-video:before {
  content: "\e919";
}
.icon-plus-circle:before {
  content: "\e92a";
}
.icon-down:before {
  content: "\e92c";
}
.icon-back:before {
  content: "\e917";
}
.icon-chart:before {
  content: "\e930";
}
.icon-edit:before {
  content: "\e94e";
}
.icon-error:before {
  content: "\e94f";
}
.icon-info:before {
  content: "\e950";
}
.icon-money:before {
  content: "\e951";
}
.icon-stream:before {
  content: "\e952";
}
.icon-toolbox:before {
  content: "\e953";
}
.icon-image:before {
  content: "\e954";
}
.icon-users:before {
  content: "\e955";
}
.icon-code:before {
  content: "\e914";
}
.icon-resize:before {
  content: "\e91d";
}
.icon-user:before {
  content: "\e92b";
}
.icon-new-window:before {
  content: "\e94d";
}
.icon-rotate-left:before {
  content: "\e944";
}
.icon-rotate-right:before {
  content: "\e945";
}
.icon-arrow-top:before {
  content: "\e943";
}
.icon-video-file:before {
  content: "\e940";
}
.icon-heart-active:before {
  content: "\e93f";
}
.icon-speaker:before {
  content: "\e933";
}
.icon-resize-min:before {
  content: "\e929";
}
.icon-smile .path1:before {
  content: "\e920";
  color: rgb(29, 29, 27);
}
.icon-smile .path2:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-smile .path3:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-smile .path4:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-smile .path5:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-smile .path6:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-smile .path7:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-smile .path8:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(29, 29, 27);
}
.icon-smile .path9:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-lock:before {
  content: "\e942";
}
.icon-location:before {
  content: "\e935";
}
.icon-send:before {
  content: "\e91f";
}
.icon-download:before {
  content: "\e941";
}
.icon-copy:before {
  content: "\e93e";
}
.icon-facebook-messenger:before {
  content: "\e936";
}
.icon-facebook:before {
  content: "\e937";
}
.icon-linkedin:before {
  content: "\e938";
}
.icon-paper-plane:before {
  content: "\e939";
}
.icon-share-alt:before {
  content: "\e93a";
}
.icon-twitter:before {
  content: "\e93b";
}
.icon-whatsapp:before {
  content: "\e93c";
}
.icon-xing:before {
  content: "\e93d";
}
.icon-shopping-cart:before {
  content: "\e932";
}
.icon-trash:before {
  content: "\e91c";
}
.icon-close:before {
  content: "\e91e";
}
.icon-invoice:before {
  content: "\e91a";
}
.icon-card:before {
  content: "\e91b";
}
.icon-user-edit:before {
  content: "\e918";
}
.icon-coins:before {
  content: "\e916";
}
.icon-list:before {
  content: "\e90e";
}
.icon-user-circle:before {
  content: "\e915";
}
.icon-search:before {
  content: "\e90f";
}
.icon-file-code:before {
  content: "\e92f";
}
.icon-settings:before {
  content: "\e910";
}
.icon-bell:before {
  content: "\e909";
}
.icon-diagram:before {
  content: "\e90a";
}
.icon-hourglass-half:before {
  content: "\e90c";
}
.icon-business:before {
  content: "\e900";
}
.icon-cart:before {
  content: "\e901";
}
.icon-channel:before {
  content: "\e902";
}
.icon-data-random:before {
  content: "\e903";
}
.icon-gutschein:before {
  content: "\e904";
}
.icon-login:before {
  content: "\e905";
}
.icon-online:before {
  content: "\e906";
}
.icon-360:before {
  content: "\e907";
}
.icon-analysis:before {
  content: "\e908";
}
.icon-table_arrow:before {
  content: "\e958";
}
.icon-cross:before {
  content: "\e957";
}
.icon-check:before {
  content: "\e956";
}
