/* You can add global styles to this file, and also import other style files */
//@import "./assets/css/common.sass";
@import "./material-theme.scss";
@import "~ui-elements/assets/common";
@import "~ui-elements/assets/admin";
@import "~ui-elements/assets/custom";
@import "./assets/icons/style.css";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "~primeicons/primeicons.css";
::ng-deep .cdk-overlay-dark-backdrop {
  background: #000;
}


::ng-deep .cdk-overlay-backdrop-showing {
  opacity: 0.8;
}

.example-container {
  width: 100%;
  margin-top: 20px;
  position: relative;
}

.card-content {
  padding: 20px;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

app-button {
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    margin-right: 10px;
  }
}

.flex-col-center {
  display: flex;
  align-items: center;
}

.input-group {
  height: 100%;
}

.heidelpaySandboxNotify {
  display: none;
}

.back-link {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
  vertical-align: middle;
  align-self: center;
}

app-button.app-button-start {
  justify-content: flex-start;

  button {
    width: fit-content;
  }
}

.overflow-scroll {
  overflow: scroll !important;
}


.cursor-pointer {
  cursor: pointer !important;
}

app-drag-and-drop {
  display: block;
  width: 100%;
  height: 100%;
}

.bg-color-orange {
  background-color: orange !important
}

.bg-color-blue {
  background-color: #0e54a9 !important
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.chat-full-screen {
  //overflow: hidden;
}

.alert-info-box {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #ffeeba;
  border-radius: .25rem;
  color: #856404;
  background-color: #fff3cd;
  width: fit-content;
}

.alert-info-message {
  color: #0c5460 !important;
  background-color: #d1ecf1 !important;
  border-color: #bee5eb !important;
}

.blue-theme-filter > form {
  display: flex;
  z-index: 101;
  position: relative;

  .dropdown-wrapper {
    min-width: max-content;
    max-width: 200px;
    width: 175px;

    .dropdown-item-select.form-control.no-border {
      height: auto !important;
    }

    .dropdown-item-select {
      font-size: 12px;
      cursor: pointer;

      &:hover {
        background-color: #efefef !important;
        color: #0e54a9 !important;
      }
    }

    .select-dropdown {
      top: 100% !important;
    }

    .form-group-select {
      padding-right: 25px;
      background-color: #0e54a9 !important;
      color: white !important;
      position: relative;
      z-index: 101;

      &:after {
        right: 15px;
        zoom: 0.8;
        border-top-color: white !important;
      }
    }

    .dropdown-item-select:last-child {
      border-radius: 0 !important;
    }

    .form-control.form-group-select.placeholder {
      font-size: 12px !important;
      border: none;
      border-radius: 0 !important;
    }

    .select-dropdown, .form-control.form-group-select {
      font-size: 12px !important;
      border-radius: 0 !important;
    }
  }

  .form-group-select {
    color: #0e54a9;
  }

  .form-group-select::after {
    border-top-color: #0e54a9;
  }

  //app-input {
  //  margin-right: 30px;
  //}
}

.owl-dt-container-buttons {
  display: none;
}

.small-media-list {
  .area-stream {
    min-width: auto !important;
  }

  .overlay-default {
    height: 155px !important;
  }

  .area-stream-header {
    //min-height: auto !important;
    max-height: none !important;
    min-height: 155px !important;

    .area-stream-header-image-container {
      min-height: 155px;
    }
  }
}

.dashboard-header-wrapper {
  display: flex;
  align-items: center;

  .alert {
    width: 98% !important;
    margin: 0 0 20px 15px;
  }
}

.empty-stream-list p, ano-loader.list-loader {
  width: 100%;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 30px;
}

.public-page-suggested-media {
  .area-stream-header {
    min-height: 213px !important;
    max-height: 213px !important;
  }
}

.card-livestreams .area-stream {
  margin-bottom: 30px !important;
}

.break-after:after {
  content: '\A';
  white-space: pre;
}

.change-forgotten-password-form, .new-password-form {
  .dropdown-box-body {
    padding: 0 20px;
  }

  .card {
    margin-bottom: 0 !important;
  }

  .bullet-list {
    margin-bottom: 35px;
  }
}

svg .grid-line line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
svg .grid-line path {
  stroke-width: 0;
}

svg .domain {
  stroke: none;
}

svg .axis-y .tick:first-child text{
  fill: none;
}

.table-container {
  min-height: 200px;
  ::ng-deep ano-loader {
    top: 50%;
    transform: translateY(-50%);
  }
}

.dropdown {
  line-height: 1;

  .btn-primary {
    &:hover,
    &:focus,
    &:active {
      background-color: darken($linkColor, 10);
    }
  }

  .dropdown-toggle {
    position: relative;
    padding-right: 30px;
    text-transform: uppercase;
    font-family: $boldFont;
    color: $linkColor;

    &::after {
      content: "";
      font-family: "icomoon" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      border: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      font-size: 19px;
    }
  }

  &.language-switcher {
    .dropdown-toggle {
      &:after {
        content: "";
      }
    }

    .btn {
      min-width: 20px;
      color: $mainColor;
      font-family: $boldFont;
      padding-right: 23px;
    }

    .dropdown-menu {
      .dropdown-item {
        color: $mainColor;
      }
    }
  }

  .btn-dropdown-xs {
    font-size: 12px;
    line-height: 1.45;
    font-family: $boldFont;
    padding: 5px 19px 5px 7px;
    border-radius: 2px;
    position: relative;
  }

  .btn-dropdown-rectangle {
    border-radius: 0;
    position: relative;
    padding: 7px 30px 7px 20px;
    font-size: 12px;
    text-transform: none;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 6px solid $whiteColor;
    }

    &.btn-inverse {
      &:after {
        border-top-color: $linkColor;
      }
    }
  }

  .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
    right: 0;
    left: auto;
    overflow: hidden;
    margin-top: 0;
    max-height: 154px;
    overflow: auto;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .dropdown-item {
      font-size: 14px;
      line-height: 18px;
      padding: 10px 15px;
      color: $linkColor;

      &:hover {
        background: $lightColor;
      }

      &.active,
      &:active {
        background: $lightColor;
      }
    }
  }

  &.open {
    .dropdown-menu {
      display: block;
      width: 100%;
      top: 100%;
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: none;
    }

    .btn {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }

    .btn-outline-dark-primary ~ .dropdown-menu {
      border-color: $linkDarkColor;
    }
  }
}

.dropdown-menu {
  &.dropdown-menu-xs {
    margin-top: 0;
    padding-top: 2px;
    padding-bottom: 2px;
    min-width: 54px;
    border-radius: 2px;

    a {
      font-size: 12px;
      padding: 2px 4px;
    }
  }
}

.border-form {
  .dropdown-wrapper {
    .form-control.select-dropdown {
      border: 1px solid $linkDarkColor !important;
      border-top-color: transparent !important;
      top: 100%;

      &.invalid {
        border-color: $dangerColor;
      }
    }
  }
}

.form-control-border {
  .dropdown-wrapper {
    .form-control.select-dropdown {
      border: 1px solid #ced4da !important;
      margin-top: -10px;
      border-top-color: transparent !important;

      &.invalid {
        border-color: $dangerColor !important;
        border-top-color: transparent !important;
      }

      &.select-opened {
        border-bottom-color: transparent !important;
      }

      &.invalid {
        border-bottom-color: transparent !important;
      }
    }
  }

  &:focus {
    border-color: #ced4da;
  }
}

.blue-theme-filter {
  .form-control.select-dropdown {
    margin-top: (-2px) !important;
  }
}

.center {
  text-align: center;
}

.status-circle {
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: 7px;
}

.success-circle {
  border: $successColor 4px solid
}

.danger-circle {
  border: $dangerColor 4px solid
}

button:focus {
  outline: none;
}


.table-material {
  min-height: 450px;
}

app-user-billing .table-material {
  min-height: auto!important;
}

app-payout-history .table-material {
  min-height: 250px!important;
}

.form-control-label {
  display: flex;
  align-items: center;
}

.table-actions-download {
  position: relative;
  display: flex;
  justify-content: center;
}
