.mat-datepicker-content .mat-calendar-body-selected {
    background-color: #0e54a9 !important;
}

.ano-month-picker {
    .mat-calendar-period-button {
      pointer-events: none;
    }
    .mat-calendar-arrow {
      display: none;
    }
}
  